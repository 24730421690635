import { useContext } from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import LoginContext from "./context/LoginContext";

function App() {
  const { isLoggedIn, logout } = useContext(LoginContext);
  let wrapperClasses = "container-fluid page-body-wrapper";

  if (!isLoggedIn) {
    wrapperClasses += " full-page-wrapper";
  }

  return (
    <div className="container-scroller">
      {isLoggedIn && <Navbar logout={logout} />}
      <div className={wrapperClasses}>
        {isLoggedIn && <Sidebar />}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
