/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";

// import {Dropdown} from "react-bootstrap";

import DropdownButton from "react-bootstrap/DropdownButton";
import {
  allOrdersApi,
  getOrdersById,
  updateOrderStatus,
} from "../services/order";
import Table from "../shared/table/Table";
import styles from "../shared/table/table.module.css";
import { filterByEmail } from "../shared/utils";
import CustomModal from "./orderModel";
import moment from "moment";

const FIELDS = [
  { key: "email", label: "Email" },
  { key: "orderId", label: "Order ID" },
  { key: "transactionId", label: "Transaction ID" },
  { key: "bankRefNo", label: "Bank Reference" },
  { key: "totalPrice", label: "Total Price" },
  { key: "paymentStatus", label: "Payment Status" },
  { key: "date", label: "Date" },
  { key: "orderDetails", label: "Order Details" },

  // { key: "orderStatus", label: "Order Status" },
];

export default function IndividualOrder() {
  const [individualOrders, setIndividualOrders] = useState<any>([]);
  const [orderDetailModal, setOrderDetailModal] = useState<ReactNode | null>(
    null
  );
  const [viewOrders, setViewOrders] = useState<any>();
  const [combineArray, setCombineArray] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalId, setModalId] = useState<any>();

  // individual order
  const individualOrderFunc = async () => {
    const ordersList = await allOrdersApi();

    const combineOrder = ordersList?.totalOrders?.map((val: any) => {
      return {
        ...val,
        ...val.User,
        ...val.Payments[0],
      };
    });
    const changePaymentStatus = combineOrder?.map((order: any) => {
      if (order.paymentStatus) {
        return { ...order, paymentStatus: "Success" };
      } else {
        return { ...order, paymentStatus: "Failed" };
      }
    });
    setIndividualOrders(changePaymentStatus);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modifiedData = useMemo(() => {
    return individualOrders?.map((val: any) => {
      const newArray: any[] = [...val.Carts, ...val.Shippings];

      let arrayValue: any[] = [];
      const combinedObject = Object.assign({}, ...newArray);
      const value = [...arrayValue, combinedObject];

      const pushArray = [];
      const newVal = pushArray.push(combinedObject);

      // setCombineArray(newArray);
      const handleStatusChange = async (orderStatusVal: any) => {
        const orderObj = {
          userId: val?.User?.id,
          orderId: val?.orderId,
          orderStatus: orderStatusVal,
        };
        const orderVal = await updateOrderStatus(orderObj);
        individualOrderFunc();
      };

      const dateChange = moment(val.updatedAt).format("DD-MM-YYYY");

      return {
        ...val,
        date: dateChange,
        orderDetails: (
          <Button
            variant="primary"
            onClick={() => {
              setModalIsOpen(true);
              setModalId(val);
            }}
          >
            View
          </Button>
        ),

        orderStatus: (
          <>
            {val.orderId ? (
              <DropdownButton
                className={styles.selectMode}
                title={val.orderStatus}
                onSelect={(eventKey) => handleStatusChange(eventKey)}
              >
                <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                <Dropdown.Item eventKey="Shipped">Shipped</Dropdown.Item>
                <Dropdown.Item eventKey="Delivered">Delivered</Dropdown.Item>
              </DropdownButton>
            ) : (
              <DropdownButton
                className={styles.selectMode}
                title={val.orderStatus}
                disabled
              >
                <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                <Dropdown.Item eventKey="Success">Success</Dropdown.Item>
                <Dropdown.Item eventKey="Failed">Failed</Dropdown.Item>
              </DropdownButton>
            )}
          </>
        ),
      };
    });
  }, [individualOrders]);

  useEffect(() => {
    individualOrderFunc();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(individualOrderFunc, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Table
        title="Order Details"
        items={modifiedData}
        filterFn={filterByEmail}
        headings={FIELDS}
      />
      <CustomModal id={modalId} isOpen={modalIsOpen} onClose={closeModal} />
    </>
  );
}
