import axiosInstance from "../helpers/axios";

// func for getting all details of the admin  based on api 
export const adminApi = async()=>{
    try{
        const allAdmin =  await axiosInstance.get("/allAdmin");
        return allAdmin.data

    }
    catch(error){
        console.log(error)
    }
}

// func for changing the password of the admin  based on api 
export const passwordAdminApi = async(passwordObj:any)=>{
    try{
        const allAdmin =  await axiosInstance.put("/changePassword",passwordObj);
        return allAdmin;
    }
    catch(error){
        console.log(error)
    }
}