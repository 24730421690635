import axiosInstance from "../helpers/axios";

// func for getting all details of the order based on api 
export const allOrdersApi = async()=>{
    try{
        const allOrders =  await axiosInstance.get("/getNonShippedOrders");
        return allOrders.data

    }
    catch(error){
        console.log(error)
    }
}

// func for getting all details of the individual order based on api 
export const individualOrderApi = async()=>{
    try{
        const allOrders =  await axiosInstance.get("/getNonShippedOrders");
        return allOrders.data

    }
    catch(error){
        console.log(error)
    }
}

// func for getting all details of the individual order based on api 
export const shippedOrderApi = async()=>{
    try{
        const shippingOrders =  await axiosInstance.get("/getShippingOrders");
        return shippingOrders.data

    }
    catch(error){
        console.log(error)
    }
}

// func for getting all details of the individual order based orderId 
export const getOrdersById = async(orderId:any)=>{
    try{
        const orderById =  await axiosInstance.put("/orderById",orderId);
        return orderById.data

    }
    catch(error){
        console.log(error)
    }
}

// func for getting all details of the individual order based orderId 
export const updateOrderStatus = async(orderObj:any)=>{
    try{
        const orderStatus =  await axiosInstance.put("/updateOrder",orderObj);
        return orderStatus.data

    }
    catch(error){
        console.log(error)
    }
}