import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoginContext from "../context/LoginContext";

export function ProtectedRoute({ element }: { element: JSX.Element }) {
  const location = useLocation();
  const { isLoggedIn } = useContext(LoginContext);

  if (isLoggedIn) {
    return element;
  }
  return <Navigate to={`/login?redirect-uri=${location.pathname}`} replace />;
}
