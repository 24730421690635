import { ChangeEventHandler, ReactNode, useState } from "react";
import { useMemo } from "react";
import SearchInput from "./SearchBar";
import { ItemFilterT, ItemHeadingT, TableItemT } from "../types";

function TableHead({ headings }: { headings: ItemHeadingT[] }) {
  return (
    <thead>
      <tr>
        <th scope="col"> S.no. </th>
        {headings?.map((heading) => (
          <th key={heading.key} scope="col">
            {" " + heading.label + " "}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function TableItem({
  item,
  rowNo,
}: {
  item: TableItemT[string][];
  rowNo: number;
}) {
  return (
    <tr>
      <th scope="row"> {rowNo} </th>
      {item?.map((value, idx) => (
        <td key={idx}> {value} </td>
      ))}
    </tr>
  );
}

function TableBody({
  items,
  headings,
}: {
  items: TableItemT[];
  headings: ItemHeadingT[];
}) {
  return (
    <tbody>
      {items?.map((item, index) => {
        const orderedItem = headings?.map((heading) => item[heading.key]);
        return <TableItem key={index} item={orderedItem} rowNo={index + 1} />;
      })}
    </tbody>
  );
}

export default function Table({
  title,
  filterFn,
  headings,
  items,
  searchPlaceHolder,
  hasSearch,
  addButton,
}: {
  title: string;
  filterFn: (searchText: string) => ItemFilterT;
  headings: ItemHeadingT[];
  items: TableItemT[];
  searchPlaceHolder: string;
  hasSearch: boolean;
  addButton: ReactNode | null;
}) {
  const [searchText, setSearchText] = useState("");

  const filterItems = useMemo(
    () => (filterFn ? items?.filter(filterFn(searchText)) : items),
    [searchText, items, filterFn]
  );

  const hasItems = filterItems?.length !== 0;
  let tableElement = (
    <div className="d-flex justify-content-center mt-5 mb-5">
      No Data Matches
    </div>
  );

  if (hasItems) {
    tableElement = (
      <div className="table-responsive">
        <table className="table">
          <TableHead headings={headings} />
          <TableBody headings={headings} items={filterItems} />
        </table>
      </div>
    );
  }

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, type } = event.target;
    if (type === "text") {
      setSearchText(value);
    }
  };

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <div className="d-flex  justify-content-between align-items-center mb-5">
              <h4 className="card-title mb-0">{title}</h4>
              {hasSearch && (
                <div className="d-flex">
                  <SearchInput
                    value={searchText}
                    onChange={onSearchChange}
                    placeHolder={searchPlaceHolder}
                  />
                  {addButton}
                </div>
              )}
            </div>
            {tableElement}
          </div>
        </div>
      </div>
    </div>
  );
}

Table.defaultProps = {
  searchPlaceHolder: "Search by email",
  filterFn: undefined,
  hasSearch: true,
  addButton: null,
};
