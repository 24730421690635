import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import IndividualOrder from "./orders/IndividualOrder";
import DeviceOrder from "./orders/DeviceOrder";
import { ProtectedRoute } from "./shared/ProtectedRoute";
import AddDevices from "./user-management/AddDevices";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Login = lazy(() => import("./login/Login"));
const PriceUpdate = lazy(() => import("./user-management/PriceUpdate"));
const Profile = lazy(() => import("./profile/Profile"));
const ContactUsDetails = lazy(() => import("./contact-us/ContactUs"));
const UserSubscription = lazy(() => import("./subscription/userSubscription"));
const UserSubscriptionPayment = lazy(
  () => import("./subscription/userSubscriptionPayment")
);

export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/orders/IndividualOrder"
          element={<ProtectedRoute element={<IndividualOrder />} />}
        />
        <Route
          path="/orders/DeviceOrder"
          element={<ProtectedRoute element={<DeviceOrder />} />}
        />
        <Route
          path="/subscription/userSubscription"
          element={<ProtectedRoute element={<UserSubscription />} />}
        />
        <Route
          path="/subscription/userSubscriptionPayment"
          element={<ProtectedRoute element={<UserSubscriptionPayment />} />}
        />
        <Route
          path="/user-management/PriceUpdate"
          element={<ProtectedRoute element={<PriceUpdate />} />}
        />
        <Route
          path="/user-management/AddDevices"
          element={<ProtectedRoute element={<AddDevices />} />}
        />
        <Route
          path="/contact-us/ContactUs"
          element={<ProtectedRoute element={<ContactUsDetails />} />}
        />
        <Route path="*" element={<ProtectedRoute element={<>Not Found</>} />} />
      </Routes>
    </Suspense>
  );
}
