/* eslint-disable jsx-a11y/img-redundant-alt */
// Modal.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOrdersById, updateOrderStatus } from "../services/order";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import styles from "../shared/table/table.module.css";

const CustomModal = ({ id, isOpen, onClose }: any) => {
  const [viewOrders, setViewOrders] = useState<any>();
  const [shippingVal, setShippingVal] = useState();
  const [showImage, setShowImage] = useState(false);
  const [deviceImage, setDeviceImage] = useState();

  const showImageFunc = (image: any) => {
    setShowImage(true);
    setDeviceImage(image);
  };

  const getOrderByIdFunc = async () => {
    const orderIdObj = {
      userId: id?.customerId,
      orderId: Number(id?.OrderId),
    };

    const getOrders = await getOrdersById(orderIdObj);
    setViewOrders(getOrders);
  };

  useEffect(() => {
    if (id !== undefined) {
      getOrderByIdFunc();
    }
  }, [id]);

  const handleStatusChange = async (orderStatusVal: any) => {
    let orderStatus;
    if (orderStatusVal === "Shipped") {
      orderStatus = true;
    } else {
      orderStatus = false;
    }

    setShippingVal(orderStatusVal);
    const orderObj = {
      userId: id?.customerId,
      orderId: Number(id?.OrderId),
      orderStatus: true,
    };
    const orderVal = await updateOrderStatus(orderObj);
    getOrderByIdFunc();
  };

  return (
    <div>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: showImage ? 0.5 : 1 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h4>Order Details</h4>

            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">Name</th> */}
                  <th scope="col" className={styles.table_title}>
                    Order ID
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Device Type
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Color
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Price
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Quantity
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Number
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Address
                  </th>
                  <th scope="col" className={styles.table_title}>
                    City
                  </th>
                  <th scope="col" className={styles.table_title}>
                    State
                  </th>
                  <th scope="col" className={styles.table_title}>
                    PinCode
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Image
                  </th>
                  <th scope="col" className={styles.table_title}>
                    Order Date
                  </th>
                </tr>
              </thead>
              {viewOrders?.order[0]?.Carts?.map((val: any, index: any) => {
                return (
                  <tbody>
                    <tr>
                      <td className={styles.table_data}>{val.OrderId}</td>
                      <td className={styles.table_data}>{val.productType}</td>
                      <td className={styles.table_data}>{val.productColor}</td>
                      <td className={styles.table_data}>{val.productPrice}</td>
                      <td className={styles.table_data}>{val.quantity}</td>
                      <td className={styles.table_data}>
                        {viewOrders?.order[0]?.Shippings[0]?.phoneNumber}
                      </td>
                      <td className={styles.table_data}>
                        {viewOrders?.order[0]?.Shippings[0]?.flatNumber},
                        {viewOrders?.order[0]?.Shippings[0]?.address}
                      </td>
                      <td className={styles.table_data}>
                        {viewOrders?.order[0]?.Shippings[0]?.city}
                      </td>
                      <td className={styles.table_data}>
                        {viewOrders?.order[0]?.Shippings[0]?.state}
                      </td>
                      <td className={styles.table_data}>
                        {viewOrders?.order[0]?.Shippings[0]?.zipcode}
                      </td>
                      <td>
                        <img
                          src={viewOrders?.deviceImages[index]}
                          alt="image"
                          style={{ width: "60px", height: "60px" }}
                          onClick={() =>
                            showImageFunc(viewOrders?.deviceImages[index])
                          }
                        />
                      </td>
                      <td className={styles.table_data}>
                        {moment(
                          viewOrders?.order[0]?.Payments[0].updatedAt
                        ).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "14px", marginTop: "9px" }}>
                Shipping Status
              </p>
              <DropdownButton
                title={shippingVal ? shippingVal : "Pending"}
                onSelect={(eventKey) => handleStatusChange(eventKey)}
              >
                <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                <Dropdown.Item eventKey="Shipped">Shipped</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {showImage && (
        <Modal
          size="sm"
          show={showImage}
          onHide={() => setShowImage(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              alt="image"
              style={{ width: "300px", height: "300px" }}
              src={deviceImage}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CustomModal;
