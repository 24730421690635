import { ChangeEventHandler } from "react";
import styles from "./table.module.css";

export default function SearchInput({
  value,
  onChange,
  placeHolder,
}: {
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeHolder: string;
}) {
  return (
    <form className={styles.form}>
      <label
        className={styles.magnifierLabel}
        htmlFor="search-input"
        id="search-label"
      >
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </label>

      <input
        id="search-input"
        className={styles.searchInput}
        aria-autocomplete="both"
        aria-labelledby="search-label"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        enterKeyHint="go"
        spellCheck="false"
        placeholder={placeHolder}
        maxLength={64}
        type="text"
        onChange={onChange}
        value={value}
      />
    </form>
  );
}
