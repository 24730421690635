import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/bubbl-logo.svg";
import LogoMini from "../../assets/images/logo-mini.svg";
import styles from "./table/table.module.css";
import { passwordAdminApi } from "../services/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getAdmin } from "../services/login";

const UpdatePasswordModal = ({ onHide }: { onHide: () => void }) => {
  const [newPassword, setNewPassword] = useState("");
  const [adminVal, setAdminVal] = useState<any>("");

  //  update function for password
  const onUpdate = async () => {
    const passwordChangeObj = {
      adminId: "1",
      newPassword: newPassword,
    };
    const passwordApi = await passwordAdminApi(passwordChangeObj); // api call
    if (passwordApi?.data.success) {
      //  toast msg , if the password isn changed
      toast.success("Password Changed", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    onHide();
  };

  // const getAdminDetails = async () => {
  //   const allVal = await getAdmin();
  //   setAdminVal(allVal);
  // };

  // useEffect(() => {
  //   getAdminDetails();
  // }, []);

  return (
    <Modal show onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              placeholder={adminVal?.email}
              disabled
              className={styles.changePassword}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function Navbar({ logout }: { logout: () => void }) {
  const [showChangePassword, setShowChangePassword] = useState(false);
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <ToastContainer />
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={LogoMini} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle
                as={Link}
                to="/contact-us/ContactUs"
                className="nav-link count-indicator"
              >
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </Dropdown.Toggle>
            </Dropdown>
          </li>
          <li className="nav-item nav-profile">
            <Dropdown align="end">
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img
                    src={require("../../assets/images/faces/profile.png")}
                    alt="user"
                  />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">Admin</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item
                  href="!#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setShowChangePassword(true);
                  }}
                >
                  <i className="mdi mdi-lock-reset mr-2 text-success"></i>&nbsp;
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  href="/user-pages/login-1"
                  onClick={(evt) => {
                    evt.preventDefault();
                    logout();
                  }}
                >
                  <i className="mdi mdi-logout mr-2 text-primary"></i>&nbsp;
                  Sign out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() => {
            document
              .querySelector(".sidebar-offcanvas")
              ?.classList.toggle("active");
          }}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      {showChangePassword && (
        <UpdatePasswordModal
          onHide={() => {
            setShowChangePassword(false);
          }}
        />
      )}
    </nav>
  );
}
