import axiosInstance from "../helpers/axios";

// func for getting all details of the device based on api 
export const allDeviceApi = async()=>{
    try{
        const allDevice =  await axiosInstance.get("/profiles/allProfiles");
        return allDevice.data

    }
    catch(error){
        console.log(error)
    }
}

// func for getting all details of the device based on api 
export const replaceDeviceApi = async(replaceObj:any)=>{
    try{
        const allDevice =  await axiosInstance.put("/device/replaceDevice",replaceObj);
        return allDevice.data

    }
    catch(error){
        console.log(error)
    }
}

// func for activate  of the device based on api t
export const activateDeviceApi = async(obj:any)=>{
    try{
        const allDevice =  await axiosInstance.put("/device/activateDevice",obj);
        return allDevice.data

    }
    catch(error){
        console.log(error)
    }
}

// func for deActivate  of the device based on api t
export const deActivateDeviceApi = async(obj:any)=>{
    try{
        const allDevice =  await axiosInstance.put("/device/deActiveDevice",obj);
        return allDevice.data

    }
    catch(error){
        console.log(error)
    }
}

// func for add new  device based on api 
export const addDeviceApi = async(obj:any)=>{
    try{
        const addDevice =  await axiosInstance.post("/device/createDevice",obj);
        return addDevice.data
    }
    catch(error){
        console.log(error)
    }
}

// func for getting all  device based on api 
export const getAllDeviceApi = async()=>{
    try{    
        const allDevices =  await axiosInstance.get("/device/allDevice");
        return allDevices.data
    }
    catch(error){
        console.log(error)
    }
}

// func for edit  device based on api 
export const updateDeviceApi = async(obj:any)=>{
    try{    
        const allDevices =  await axiosInstance.put("/device/updateDevice",obj);
        return allDevices.data
    }
    catch(error){
        console.log(error)
    }
}