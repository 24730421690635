// Modal.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOrdersById, updateOrderStatus } from "../services/order";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import styles from "../shared/table/table.module.css";

const DeviceOrderModal = ({ id, isOpen, onClose }: any) => {
  const [viewOrders, setViewOrders] = useState<any>();
  const [shippingVal, setShippingVal] = useState();
  const [showImage, setShowImage] = useState(false);
  const [deviceImage, setDeviceImage] = useState();

  const getOrderByIdFunc = async () => {
    const orderIdObj = {
      userId: id?.customerId,
      orderId: Number(id?.OrderId),
    };

    const getOrders = await getOrdersById(orderIdObj);
    setViewOrders(getOrders);
  };

  const showImageFunc = (image: any) => {
    setShowImage(true);
    setDeviceImage(image);
  };

  useEffect(() => {
    if (id !== undefined) {
      getOrderByIdFunc();
    }
  }, [id]);

  const handleStatusChange = async (orderStatusVal: any) => {
    let orderStatus;
    if (orderStatusVal === "Shipped") {
      orderStatus = true;
    } else {
      orderStatus = false;
    }

    setShippingVal(orderStatusVal);
    const orderObj = {
      userId: id?.customerId,
      orderId: Number(id?.OrderId),
      orderStatus: true,
    };
    const orderVal = await updateOrderStatus(orderObj);
    getOrderByIdFunc();
  };

  return (
    <div>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h4>Order Details</h4>

            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">Name</th> */}
                  <th scope="col">Order ID</th>
                  <th scope="col">Device Type</th>
                  <th scope="col">Color</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Address</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">PinCode</th>
                  <th scope="col">Image</th>
                  <th scope="col">Order Date</th>
                </tr>
              </thead>
              {viewOrders?.order[0]?.Carts?.map((val: any, index: any) => {
                return (
                  <tbody>
                    <tr>
                      <td>{val.OrderId}</td>
                      <td>{val.productType}</td>
                      <td>{val.productColor}</td>
                      <td>{val.productPrice}</td>
                      <td>{val.quantity}</td>
                      <td>
                        {viewOrders?.order[0]?.Shippings[0]?.flatNumber},{" "}
                        {viewOrders?.order[0]?.Shippings[0]?.address}
                      </td>
                      <td>{viewOrders?.order[0]?.Shippings[0]?.city}</td>
                      <td>{viewOrders?.order[0]?.Shippings[0]?.state}</td>
                      <td>{viewOrders?.order[0]?.Shippings[0]?.zipcode}</td>
                      <td>
                        <img
                          src={viewOrders?.deviceImages[index]}
                          alt="image"
                          style={{ width: "60px", height: "60px" }}
                          onClick={() =>
                            showImageFunc(viewOrders?.deviceImages[index])
                          }
                        />
                      </td>
                      <td>
                        {moment(
                          viewOrders?.order[0]?.Payments[0].updatedAt
                        ).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </Modal.Body>
      </Modal>

      {showImage && (
        <Modal
          show={showImage}
          onHide={() => setShowImage(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              alt="image"
              style={{ width: "300px", height: "300px" }}
              src={deviceImage}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default DeviceOrderModal;
