import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Face1 from "../../assets/images/faces/profile.png";
import { Collapse } from "react-bootstrap";

export default function Sidebar() {
  const [pages, setPages] = useState({
    generalPagesMenuOpen: false,
    basicUiMenuOpen: false,
    formElementsMenuOpen: false,
    tablesMenuOpen: false,
    iconsMenuOpen: false,
    chartsMenuOpen: false,
    userPagesMenuOpenOrder: false,
    userPagesMenuOpenSubscription: false,
    userPagesMenuOpenManagement: false,
    errorPagesMenuOpen: false,
  } as const);

  const toggleMenuState = (menuState: keyof typeof pages) => {
    setPages((currPage) => {
      return { ...currPage, [menuState]: !currPage[menuState] };
    });
  };

  const location = useLocation();

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a
            href="!#"
            className="nav-link"
            onClick={(evt) => evt.preventDefault()}
          >
            <div className="nav-profile-image">
              <img src={Face1} alt="profile" />
              <span className="login-status online"></span>{" "}
              {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2">Admin</span>
              <span className="text-secondary text-small">Bubbl Team</span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">Dashboard</span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        <li
          className={isPathActive("/profile") ? "nav-item active" : "nav-item"}
        >
          <Link className="nav-link" to="/profile">
            <span className="menu-title">Profiles</span>
            <i className="mdi mdi-account menu-icon"></i>
          </Link>
        </li>

        <li className={isPathActive("#!") ? "nav-item" : "nav-item"}>
          <div
            className={
              pages.userPagesMenuOpenOrder
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("userPagesMenuOpenOrder")}
            data-toggle="collapse"
          >
            <span className="menu-title">Orders</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-cart menu-icon"></i>
          </div>
          <Collapse in={pages.userPagesMenuOpenOrder}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/orders/IndividualOrder")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/orders/IndividualOrder"
                >
                  Individual Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/orders/DeviceOrder")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/orders/DeviceOrder"
                >
                  Orders Shipped
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive("#!") ? "nav-item" : "nav-item"}>
          <div
            className={
              pages.userPagesMenuOpenSubscription
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("userPagesMenuOpenSubscription")}
            data-toggle="collapse"
          >
            <span className="menu-title">Subscriptions</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-check menu-icon"></i>
          </div>
          <Collapse in={pages.userPagesMenuOpenSubscription}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/subscription/userSubscription")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/subscription/userSubscription"
                >
                  User Subscriptions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/subscription/userSubscriptionPayment")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/subscription/userSubscriptionPayment"
                >
                  Subscription Payments
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li className={isPathActive("#!") ? "nav-item" : "nav-item"}>
          <div
            className={
              pages.userPagesMenuOpenManagement
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("userPagesMenuOpenManagement")}
            data-toggle="collapse"
          >
            <span className="menu-title">Product Management</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-group menu-icon"></i>
          </div>
          <Collapse in={pages.userPagesMenuOpenManagement}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/user-management/PriceUpdate")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/user-management/PriceUpdate"
                >
                  Edit Products
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/user-management/AddDevices")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/user-management/AddDevices"
                >
                  Add Device Number
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        <li
          className={
            isPathActive("/user-pages") ? "nav-item active" : "nav-item"
          }
        ></li>
      </ul>
    </nav>
  );
}
