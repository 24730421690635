/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import styles from "./usermanagement.module.css";
import { AddDeviceT, TableItemT } from "../shared/types";
import Table from "../shared/table/Table";
import {
  addDeviceApi,
  getAllDeviceApi,
  updateDeviceApi,
} from "../services/device";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import QRCodeStyling from "qr-code-styling";
import { v4 as uuidv4 } from "uuid";
import { filterByDeviceTypeOrId } from "../shared/utils";

const qrCodeWhite = new QRCodeStyling({
  width: 1000,
  height: 1000,
  dotsOptions: {
    color: "#ffffff",
    type: "square",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20,
  },
  backgroundOptions: {
    color: undefined, // Set the desired background color
  },
});

const qrCodeBlack = new QRCodeStyling({
  width: 1000,
  height: 1000,
  dotsOptions: {
    color: "#000000",
    type: "square",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20,
  },
  backgroundOptions: {
    color: undefined, // Set the desired background color
  },
});
const FIELDS = [
  { key: "deviceUid", label: "Device Number" },
  { key: "deviceType", label: "Device Type" },
  { key: "view", label: "View Details" },
];

const filterByDeviceId = (searchText: string) => {
  return (item: TableItemT) => {
    if (item?.deviceUid) {
      return (
        typeof item.deviceUid === "string" &&
        item.deviceUid.startsWith(searchText)
      );
    } else if (item?.deviceType) {
      return (
        typeof item.deviceType === "string" &&
        item.deviceType.startsWith(searchText)
      );
    }
  };
};

function CreateModal({
  handleClose,
  uniqueValue,
  onCreated,
}: {
  handleClose: () => void;
  onCreated: (data: AddDeviceT) => void;
  uniqueValue: any;
}) {
  const [deviceNumber, setDeviceNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fileExt, setFileExt] = useState<any>("png");
  const [deviceType, setDeviceType] = useState("");
  const [deviceTypeError, setDeviceTypeError] = useState("");

  const ref = useRef(null);
  const qrUrl = `https://bubbl.cards/profile/${uniqueValue}`;

  // func for add the device
  const handleCreate = async () => {
    const addDeviceObj = {
      deviceUid: uniqueValue,
      deviceType: deviceType,
    };

    if (deviceType === "") {
      setDeviceTypeError("Device Type cannot be empty");
    } else {
      if (uniqueValue !== "") {
        const newDevice = await addDeviceApi(addDeviceObj); //api call
        if (newDevice?.message === "Device exist") {
          setErrorMsg("Device already exist");
        } else {
          onCreated(newDevice.deviceFunction);
          setErrorMsg("");
          setDeviceTypeError("");
          handleClose();
        }
      }
    }
  };

  const handleStatusChange = async (orderStatusVal: any) => {
    setDeviceType(orderStatusVal);
    setDeviceTypeError("");
  };

  useEffect(() => {
    if (ref.current) {
      qrCodeWhite.append(ref.current);
      qrCodeBlack.append(ref.current);
    }
  }, []);

  useEffect(() => {
    qrCodeWhite.update({
      data: qrUrl,
    });
    qrCodeBlack.update({
      data: qrUrl,
    });
  }, [qrUrl]);

  const downloadQR = () => {
    qrCodeWhite.update({
      data: qrUrl,
    });
    qrCodeBlack.update({
      data: qrUrl,
    });

    qrCodeWhite.download({
      extension: fileExt,
    });
    qrCodeBlack.download({
      extension: fileExt,
    });
  };

  return (
    <Modal show onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-3">
            <Modal.Title>Auto Generated Device Number</Modal.Title>
            <Form.Control
              style={{ marginTop: "10px" }}
              type="text"
              placeholder="Enter Device Number"
              value={uniqueValue}
              onChange={(event) => {
                const { value } = event.target;
                setDeviceNumber(value);
              }}
              disabled
            />
          </Form.Group>
          <Modal.Title>Select Device Type*</Modal.Title>
          <DropdownButton
            className={styles.selectMode}
            title={deviceType ? deviceType : "Choose device type"}
            onSelect={(eventKey) => handleStatusChange(eventKey)}
            style={{ marginTop: "10px", marginBottom: "30px" }}
          >
            <Dropdown.Item eventKey="Card">Card</Dropdown.Item>
            <Dropdown.Item eventKey="Tile">Tile</Dropdown.Item>
            <Dropdown.Item eventKey="Socket">Socket</Dropdown.Item>
          </DropdownButton>
          {deviceTypeError && (
            <span className="text-danger" role="alert">
              {deviceTypeError}
            </span>
          )}
          <div>
            <div className={styles.canvas_img} ref={ref} />
          </div>

          <div>
            <Button
              onClick={downloadQR}
              style={{ fontSize: "10px", width: "107px", marginTop: "10px" }}
            >
              Download QR
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {errorMsg && (
        <span className="text-danger" role="alert">
          {errorMsg}
        </span>
      )}
      <Modal.Footer>
        <Button variant="primary" onClick={handleCreate}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function AddDevices() {
  const [allDevice, setAllDevice] = useState<any[]>([]);

  const [showCreate, setShowCreate] = useState(false);

  const [editValues, setEditValues] = useState<any>();
  const [editModal, setEditModal] = useState(false);
  const [uuid, setUUID] = useState("");

  // func for get all device
  const getAllDeviceFunc = async () => {
    const allDevices: any = await getAllDeviceApi();
    const sortData = allDevices?.getDevice.sort(
      (a: any, b: any) => b.id - a.id
    );
    setAllDevice(sortData);
  };

  useEffect(() => {
    getAllDeviceFunc();
  }, []);

  const editOpenModal = (editValues: any) => {
    setEditValues(editValues);
  };

  const tableData = useMemo(
    () =>
      allDevice?.map((profile: any) => {
        return {
          ...profile,
          view: (
            <Button
              variant="primary"
              onClick={() => {
                setEditModal(true);
                editOpenModal(profile);
              }}
            >
              View
            </Button>
          ),
        };
      }),
    [allDevice]
  );

  let modal: React.ReactNode = null;

  const handleClose = () => {
    setEditModal(false);
  };

  const updateDeviceHandler = (key: string, value: string | number) => {
    setEditValues({ ...editValues, [key]: value });
  };

  const handleUniqueIdUpdate = async () => {
    const deviceUpdateObj = {
      deviceId: editValues?.id,
      deviceUid: editValues?.deviceUid,
    };
    const updateApi = await updateDeviceApi(deviceUpdateObj);
    setEditModal(false);
    getAllDeviceFunc();

    // const updateObj = {
    //   deviceId: selectDevice?.id,
    //   devicePrice: Number(selectDevice?.price),
    //   description: selectDevice?.deviceDescription,
    // };
    // const priceApi = await productEditApi(priceObj);
    // getAllProducts();
    // setShow(false);
    // if (selectedAddDevice.deviceUid) {
    //   updateAddDevice(selectedAddDevice)
    //     .then((AddDevice) => {
    //       handleUpdate(AddDevice);
    //       // setSelectedDevice(null);
    //     })
    //     .catch(() => {
    //       console.log("Unexpected Error occurred");
    //     });
    // }
  };

  modal = (
    <Modal
      show={editModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Modal.Title>Device Number</Modal.Title>
            <Form.Control type="text" value={editValues?.deviceUid} disabled />
          </Form.Group>
          <Form.Group className="mb-3">
            <Modal.Title>Device Type</Modal.Title>
            <Form.Control type="text" value={editValues?.deviceType} disabled />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={handleUniqueIdUpdate}>
          Update
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
  // }

  function generate16DigitUUID() {
    const fullUUID = uuidv4();
    const sixteenDigitUUID = fullUUID;

    return sixteenDigitUUID;
  }

  const handleClick = () => {
    const generatedUUID = generate16DigitUUID();

    setUUID(generatedUUID);
  };

  return (
    <>
      <Table
        title="Add Device Number"
        items={tableData}
        headings={FIELDS}
        filterFn={filterByDeviceTypeOrId}
        searchPlaceHolder="Search by AddDevice type"
        addButton={
          <Button
            style={{ lineHeight: "0" }}
            className="ms-2"
            variant="primary"
            onClick={() => {
              handleClick();
              setShowCreate(true);
            }}
          >
            <span style={{ fontSize: "16px" }}>+</span> Add Device
          </Button>
        }
      />
      {showCreate && (
        <CreateModal
          uniqueValue={uuid}
          handleClose={() => {
            setShowCreate(false);
          }}
          onCreated={(device: AddDeviceT) => {
            setAllDevice((devices) => {
              return [device, ...devices];
            });
            setShowCreate(false);
          }}
        />
      )}
      {modal}
    </>
  );
}
