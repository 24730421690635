import { TableItemT } from "./types";

export function filterByEmail(searchText: string) {
  return (item: TableItemT) => {
    if(item?.emailId){
      return typeof item.emailId === "string" && item.emailId.toLowerCase().startsWith(searchText);
    }
    else{
      return typeof item.email === "string" && item.email.toLowerCase().startsWith(searchText);
    }
  };
}

export function filterByOrderIdOrEmail(searchText: string) {
  return (item: TableItemT) => {
    if (typeof item.orderId === "number" && typeof item.email === "string") {
      return (
        item.orderId.toString().startsWith(searchText) ||
        item.email.toLowerCase().startsWith(searchText)
      );
    }
    return false;
  };
}

export function filterByDeviceTypeOrId(searchText: string) {
  return (item: TableItemT) => {
    if (typeof item.deviceType === "string" && typeof item.deviceUid === "string") {
      return (
        item.deviceType.toLowerCase().startsWith(searchText) ||
        item.deviceUid.startsWith(searchText)
      );
    }
    return false;
  };
}