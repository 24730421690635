import { PropsWithChildren, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const TOKEN = "token";

const getItem = (key: string) => localStorage.getItem(key);
const removeItem = (key: string) => localStorage.removeItem(key);
const saveItem = (key: string, value: string) =>
  localStorage.setItem(key, value);

const getToken = () => getItem(TOKEN);
const removeToken = () => removeItem(TOKEN);
const saveToken = (token: string) => saveItem(TOKEN, token);

const token = getToken();
const initialValue = {
  getToken,
  isLoggedIn: Boolean(token),
  storeData: (token: string) => {
    /* do nothing */
  },
  logout: () => {
    /* do nothing */
  },
};

const LoginContext = createContext(initialValue);

export const LoginContextProvider = ({ children }: PropsWithChildren) => {
  const [isLoggedIn, setIsLoggedIn] = useState(initialValue.isLoggedIn);

  const navigate = useNavigate();

  return (
    <LoginContext.Provider
      value={{
        getToken,
        isLoggedIn,
        storeData: (token: string) => {
          saveToken(token);
          setIsLoggedIn(true);
        },
        logout: () => {
          removeToken();
          navigate("/login");
          setIsLoggedIn(false);
        },
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
